'use client' // Error components must be Client Components
 
import { useEffect } from 'react'
import Link from 'next/link';
export default function Error({ error, reset }) {
  useEffect(() => {
    // Log the error to an error reporting service
    console.error(error)
  }, [error])
 
  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <h2 className="text-3xl font-bold mb-8">發生不明錯誤</h2>
      <p className="text-lg text-gray-600 mb-4">
        請稍後再試，若問題持續發生可將以下文字複製或者截圖回報發送至 Facebook 粉絲專頁或是 Email 給我們。
        <br />
        <pre>{JSON.stringify(error, null, 2)}</pre>
      </p>
      <Link 
        href="/" 
        className="text-lg text-primary hover:text-primary hover:underline hover:underline-offset-4"
      >
        返回首頁
      </Link>
    </div>
  )
}